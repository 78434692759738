/*
* 公開画面共通
*/
@import "base/_variables";

body {
  font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", sans-serif;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  font-feature-settings: "palt" 1;
  color: #595757;
  animation: fadein 1s linear 0s 1;
  -webkit-animation: fadein 1s linear 0s 1;
  @include pc() {
    min-width: 1280px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: bold;
}
table {
}
img,
figure,
figcaption {
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: top;
}
img {
  max-width: 100%;
  height: auto;
}
.object-fit {
  object-fit: contain;
  font-family: "object-fit: contain;";
}
* {
  box-sizing: border-box;
  position: relative;
}
a,
a:hover,
a img,
a:hover img,
button,
button:hover {
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
a {
  color: #595757;
  text-decoration: none;
  display: inline-block;
}
a:hover,
button:hover {
  opacity: 0.6;
}

p {
  margin: 0;
  padding: 0;
  text-align: justify;
  text-justify: distribute;
}
ul,
ol,
li,
dl,
dt,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}
table {
  width: 100%;
  border: none;
}
tbody {
  border-top: 0px;
}
th {
  padding-right: 0em;
  border-bottom: none;
}
tbody th {
  border-bottom: none;
}
.pc {
  display: inline;
}
.sp {
  display: none;
}
#Wrapper {
  overflow: hidden;
}

/*animation
-------------------------------------------------------*/
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*
* flex
-------------------------------------------------------*/
.flex,
header .sns,
footer .sns,
#fMenu,
#Wrapper,
.Intro .sns,
.Pager ul,
.Pager .prev,
.Pager .next,
.Info dl > * {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /*align-items: flex-start;*/
}

/* header
-------------------------------------------------------*/
#top {
  display: inline;
}
header,
header a {
  color: #ffffff;
}
#hCont1 {
  background: #0098ff;
  padding: 10px 0;
}
#hCont1 .Logo {
  position: relative;
  width: 80px;
  z-index: 100000;
}
#hCont1 h2 {
  margin: 0 auto 0 30px;
  font-size: 1.8rem;
  line-height: 3rem;
  font-weight: normal;
}
#hCont1 .sns > *:nth-of-type(n + 2) {
  margin-left: 10px;
}
header .sns a img,
footer .sns a img {
  width: 30px;
}
#hCont2 {
  background: #000000;
  padding: 20px 0;
}

/*
* 検索エリア
*/
.formSearch {
  h2 {
    font-size: 1.5rem;
    font-weight: normal;
    img {
      width: 18px;
      display: inline;
    }
  }
  /*
  * 検索フォーム
  */
  .searchClass {
    display: block;
    margin-right: 0.8rem;
    @include pc {
      display: inline-block;
    }
  }
  .searchWord {
    display: inline-block;
    margin-top: 10px;
    margin-right: 0.8rem;
    width: 160px;
    @include pc() {
      margin-top: 0;
    }
  }
  input[type=submit] {
    margin-top: 10px;
    height: 32px;
    @include pc() {
      margin-top: 0;
    }
  }
}

#navArea li a img {
  width: 30px;
}

/*footer
-------------------------------------------------------*/
footer {
  background: #cdcdcd;
  margin-top: 30px;
}
footer .inner {
  padding-top: 30px;
  padding-bottom: 30px;
}
#fName {
  font-size: 1.2rem;
  line-height: 2rem;
}
#fName .Logo {
  width: 120px;
}
#fMenu {
  font-size: 1.3rem;
  line-height: 30px;
}
#fMenu .sns li:nth-of-type(n + 2) {
  margin-left: 20px;
}
#fMenu .sns a img {
  margin-right: 5px;
}
#fCompany {
  border: #808080 1px solid;
  border-left: none;
  border-right: none;
  width: 100%;
  margin-top: 30px;
  padding: 10px 0;
}
#Copy {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}
#btnTop {
  position: fixed;
  right: 0px;
  bottom: 0px;
  z-index: 99999;
}
#btnTop a {
  background: #cdcdcd;
  color: #595757;
  width: 50px;
  height: 50px;
  padding: 5px;
  font-size: 1.2rem;
  line-height: 16px;
  text-decoration: none;
  text-align: center;
  display: block;
}
#btnTop a::before {
  font-family: "Font Awesome 5 Free";
  content: "\f106";
  font-size: 1.6rem;
  line-height: 18px;
  display: block;
}

/*
* pc
*/
@media(min-width: 768px) {
  #Wrapper > *:nth-child(1).Side {
    width: 270px;
  }
  #Wrapper.Page > #Content {
    border-left: #808080 2px solid;
    width: 900px;
    margin-top: 30px;
    padding-left: 28px;
  }
  .inner {
    width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
  }

  /*flex
  -------------------------------------------------------*/
  .flex.sb,
  .column2,
  .column3,
  #Wrapper,
  #fMenu,
  .Info dl > * {
    justify-content: space-between;
  }
  .flex.center {
    justify-content: center;
  }
  .flex.vcenter {
    align-items: center;
  }
  .column2 > * {
    width: 48%;
    height: auto;
  }
  .Banner.column2 > * {
    width: 310px;
  }
  .column3 > * {
    width: 30%;
    height: auto;
  }

  /*header
	-------------------------------------------------------*/
  #hCont1 .flex {
    align-items: flex-end;
  }
  #hCont2 .flex {
  }
  #searchWrap > *:nth-child(n + 2) {
    margin-left: 1em;
  }
  #navArea nav ul {
    line-height: 30px;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  #navArea nav ul li:nth-of-type(n + 2) {
    margin-left: 10px;
    padding-left: 10px;
  }
  #navArea nav ul li:nth-of-type(n + 2):not(:last-of-type)::before {
    content: "";
    border-left: #ffffff 1px solid;
    width: 0;
    height: 16px;
    margin-top: -8px;
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
  }

  /*footer
	-------------------------------------------------------*/
  #fName {
    width: 225px;
  }
  #fName p {
    margin-top: 30px;
  }
  #fMenu,
  #fCompany {
    width: 750px;
  }
  #fMenu > ul:nth-child(1),
  #fMenu > ul:nth-child(4),
  #fMenu .sns {
    width: 220px;
  }
  #fMenu > ul:nth-child(2),
  #fMenu > ul:nth-child(3) {
    width: 130px;
  }
  #fMenu .sns {
    position: absolute;
    right: 0;
    top: 90px;
  }
  #fCompany {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  #fCompany li:nth-of-type(n + 2) {
    margin-left: 40px;
  }
}

/*sphone
-------------------------------------------------------*/
@media screen and (max-width: 767px) {
  body {
    font-size: 1.3rem;
    line-height: 2em;
  }
  .pc {
    display: none;
  }
  .sp {
    display: inline;
  }
  #Wrapper {
    padding: 0 10px;
  }
  #Wrapper.Page > #Content {
    margin-top: 20px;
    width: 100%;
    order: 1;
  }
  #Wrapper.Page > .Side {
    border-top: #808080 1px solid;
    margin-top: 40px;
    order: 2;
  }

  /*header
	-------------------------------------------------------*/
  #hCont1 {
    padding: 10px;
  }
  #hCont1 .Logo {
    width: 60px;
  }
  #hCont1 h2,
  #hCont1 .sns {
    display: none;
  }
  #searchWrap {
    justify-content: center;
  }
  #searchWrap h2 + *,
  #searchWrap button {
    margin-left: 10px;
  }
  #searchWrap textarea,
  #searchWrap button {
    margin-top: 10px;
  }

  /*
  * menu
	*/
  #navArea {
    nav {
      display: block;
      position: fixed;
      right: -80%;
      top: 0;
      width: 80%;
      height: 100%;
      background: #000000;
      transition: all 0.5s;
      z-index: 99999;
      opacity: 1.0;
      @include pc() {
        position: fixed;
        right: 0;
        top: 0;
        width: 80%;
        height: 100%;
        background: #000000;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        transition: all 0.5s;
        z-index: 3;
        opacity: 0;
      }
      &.open {
        right: 0;
      }
    }  
  }
  #navArea nav ul {
    list-style: none;
    margin-top: 60px;
    padding: 0;
  }
  #navArea nav ul li {
    position: relative;
    margin: 0;
    border-top: #ffffff 1px solid;
  }
  #navArea nav ul li a {
    display: block;
    color: #ffffff;
    padding: 20px;
    font-size: 1.6rem;
    text-decoration: none;
    transition-duration: 0.2s;
  }
  #navArea nav ul li a:hover {
    background: #333333;
    color: #ffffff;
  }
  #navArea nav ul > li.dd > * > a {
    pointer-events: none;
    position: relative;
  }
  #navArea nav ul > li.dd > * > a:hover {
    width: 0px;
  }
  #navArea .sub {
    display: none;
  }
  #navArea .menu {
    background: #faf7ea;
  }
  #navArea .menu li a::before {
    top: 3px;
  }
  #navArea nav ul > li > div > a::before {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-family: "Font Awesome 5 Free";
    content: "\f107";
    display: block;
    position: absolute;
    right: 10px;
    top: 15px;
  }
  #navArea nav ul > li.dd > div.open > a::before {
    transform: rotate(180deg);
  }
  .toggleBtn {
    display: block;
    background-color: #0098ff;
    position: fixed;
    top: 0px;
    right: 0px;
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    transition: all 0.5s;
    cursor: pointer;
    z-index: 3;
  }
  .toggleBtn span {
    display: block;
    position: absolute;
    left: 10px;
    width: 30px;
    height: 2px;
    background-color: #ffffff;
    border-radius: 4px;
    transition: all 0.5s;
  }
  .toggleBtn span:nth-child(1) {
    top: 14px;
  }
  .toggleBtn span:nth-child(2) {
    top: 24px;
  }
  .toggleBtn span:nth-child(3) {
    bottom: 14px;
  }
  .open .toggleBtn {
    background-color: #000000;
  }
  .open .toggleBtn span {
    background-color: #ffffff;
  }
  .open .toggleBtn span:nth-child(1) {
    -webkit-transform: translateY(10px) rotate(-315deg);
    transform: translateY(10px) rotate(-315deg);
  }
  .open .toggleBtn span:nth-child(2) {
    opacity: 0;
  }
  .open .toggleBtn span:nth-child(3) {
    -webkit-transform: translateY(-10px) rotate(315deg);
    transform: translateY(-10px) rotate(315deg);
  }

  #Mask {
    display: none;
    transition: all 0.5s;
  }
  .open #Mask {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.3;
    z-index: 2;
    cursor: pointer;
  }

  /*footer
	-------------------------------------------------------*/
  footer .inner {
    padding: 30px 10px;
  }
  #fName,
  #fMenu > ul:nth-child(1),
  #fMenu > ul:nth-child(4) {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  #fName .Logo {
    width: 60px;
    margin-right: 20px;
  }
  #fMenu ul {
    margin-top: 20px;
  }
  #fMenu > ul:nth-child(1),
  #fMenu > ul:nth-child(4),
  #fMenu .sns {
    width: 100%;
  }
  #fMenu > ul:nth-child(1) li:nth-of-type(n + 2),
  #fMenu > ul:nth-child(2),
  #fMenu > ul:nth-child(3),
  #fMenu > ul:nth-child(4) li {
    width: 50%;
  }
  #fCompany {
    border: #808080 1px solid;
    border-left: none;
    border-right: none;
    width: 100%;
    margin-top: 30px;
    padding: 10px 0;
  }
  #Copy {
    font-size: 1.1rem;
  }
}

/*
* 共通
*/
#Wrapper h2 {
  background: #a6a6a6;
  color: #ffffff;
  font-size: 1.6rem;
  line-height: 3.2rem;
  text-align: center;
}
#Wrapper h2,
.sLink,
.sBanner,
.sSocial {
  margin-top: 30px;
  img, video {
    width: 100%;
    height: auto !important;
  }
}
#Wrapper h2 + * {
  margin-top: 10px;
}
#detailShop h2 + * {
  margin-top: 20px;
}
#Wrapper h2.tBg1 {
  background: #ff9300;
}
#Wrapper h2.tBg2 {
  background: #0098ff;
}
#Wrapper h2.tBg3 {
  background: #bca700;
}
#Wrapper h3 {
  font-size: 1.6rem;
  line-height: 3.2rem;
}
.annotation {
  font-size: 1.2rem;
  line-height: 20px;
}
#Wrapper {
  h2.pageTitle {
    margin-top: 0;
  }
}

/*
* 各サイトへのリンクバナー
*/
.sLink {
  li {
    margin-bottom: 10px;
    width: 48%;
    &:nth-of-type(2n) {
      margin-right: 0;
    }
    a {
      width: 100%;
    }
  }
}
.sCategory {
  a {
    display: block;
    font-weight: bold;
  }
  .ranking {
    > a {
      padding-bottom: 10px;
      color: #ff9300;
      line-height: 26px;
      img {
        width: 31px;
        margin-right: 10px;
        display: inline-block;
      }
    }
  }
}
.sApp {
  border-top: #808080 1px solid;
  margin-top: 30px;
  padding-top: 10px;
}
.sApp .appDl {
  margin-top: 10px;
}
.sApp .appDl > * img {
  height: 40px;
  width: auto;
  border-radius: 5px;
}
.sApp .qrWrap {
  margin-top: 20px;
}
.sApp .qr {
  width: 100px;
  margin-right: 10px;
}
.sApp p + p {
  margin-top: 1em;
}
.sBanner ul > *:nth-child(n + 2),
.sSocial > *:nth-child(n + 2) {
  margin-top: 10px;
}
.sSocial #twitter {
  border: #eeeeee 1px solid;
}

/*btn
-------------------------------------------------------*/
textarea {
  border: none;
}
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}
input[type="submit"],
input[type="button"],
button {
  cursor: pointer;
}
.btn1,
.btn2 {
  width: 150px;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
  display: block;
}
.btn1 {
  border: #808080 1px solid;
}
.btn2 {
  background: #0098ff;
  color: #ffffff;
  padding: 10px;
}

/*
-------------------------------------------------------*/
.inline li {
  margin-right: 1em;
  line-height: 2em;
  display: inline;
}
.square > *:nth-child(n + 2) {
  margin-top: 10px;
}
.square li,
.square > div dt {
  padding-left: 1.5em;
}
.square li::after,
.square > div dt::after {
  content: "■";
  position: absolute;
  left: 0;
  top: 0;
}

/*
* カテゴリ
*/
.ul1 > *:nth-of-type(n + 2) {
  margin-top: 10px;
}
.ul1 li {
  border-bottom: #808080 1px solid;
  padding-bottom: 10px;
}
.ul1 li a {
  display: block;
}
.menuCategory {
  /*
  * タイトル
  */
  h2 {
    margin-top: 0 !important;
  }
  ul {
    display: none;
    li {
      &:last-of-type {
        border-bottom: none;
      }
      &.categoryIndent {
        &-0 {
          padding-left: 0;
          border-bottom: 1px solid #808080;
        }
        &-1 { padding-left: 1.6rem; }
        &-2 { padding-left: 1.6rem; }
        &-3 { padding-left: 1.6rem; }
        &-4 { padding-left: 1.6rem; }
        &-5 { padding-left: 1.6rem; }
      }
      a {
        display: block;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 0;
        /*
        * 子カテゴリありのとき
        */
        &.categoryShrink {
          &::after {
            @include pc() {
              font-family: 'Font Awesome 5 Free';
              content: "\f105";
              font-size: 1.6rem;
              display: block;
              position: absolute;
              right: 0;
              top: calc(50% - 0.8rem);
            }
          }
        }
      }
    }
  }
  &> ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    border-top: none;
    @include pc() {
      display: block;
    }
    li {
      width: 49%;
      @include pc() {
        width: 100%;
      }
    }
  }
}

/*dl
-------------------------------------------------------*/
.dl1 > *:nth-child(n + 2) {
  margin-top: 10px;
}
.dl1 dt span {
  background: #ff3059;
  color: #ffffff;
  margin-left: 0.5em;
  padding: 0 5px;
  font-size: 1.1rem;
  line-height: 16px;
  border-radius: 8px;
  display: inline-block;
}
.dl2 > * {
  border-bottom: #808080 1px solid;
  padding-bottom: 10px;
}

/*shop
-------------------------------------------------------*/
.listThumb li a {
  display: block;
}
.listThumb li a::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.listThumb li .img {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit: cover;";
}
.listThumb li .note {
  background: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  width: 100%;
  padding: 10px;
  position: absolute;
  left: 0;
  bottom: 0;
}
#Wrapper .listThumb li .note h3 {
  font-size: 1.3rem;
  line-height: 2.6rem;
}
#listShop .cont:nth-child(n + 2) {
  border-top: #808080 1px solid;
  margin-top: 30px;
  padding-top: 30px;
}
#listShop .cont a {
  display: block;
}
#listShop .cont a > *:nth-child(n + 2) {
  margin-top: 20px;
}
#listShop .note > *:nth-child(n + 2),
#detailShop .note > *:nth-child(n + 2) {
  margin-top: 10px;
}

/*
* ページャー
*/
.Pager, .pagination {
  margin-top: 40px;
}
.Pager, .pagination {
  li {
    min-width: 30px;
    word-break: keep-all;
    &:nth-of-type(n+2) {
      margin-left: 10px;
    }
    > * {
      height: 30px;
      text-align: center;
      display: block;
    }
    &:first-of-type,
    &:last-of-type {
      width: 60px;
    }
    span, a {
      width: 100%;
      border: #595858 1px solid;
      color: #595858;
      word-break: keep-all;
    }
    &.active {
      .page-link {
        background-color: #595858;
        color: #ffffff;
        border-color: #595858;
      }
    }
    &.disabled {
      .page-link {
        border-color: #595858;
      }
    }
  }
  .prev, .next {
    > * {
      width: 60px;
    }
  }
}
.Pager .prev::after,
.Pager .next::before {
  content: "･･･";
  line-height: 30px;
  display: inline-block;
}
.Pager .prev::after {
  margin-left: 10px;
}
.Pager .next::before {
  margin-right: 10px;
}
.Pager .current > * {
  background: #595858;
  color: #ffffff;
}
.Pager a {
  border: #595858 1px solid;
  color: #595858;
}

/*shop detail
-------------------------------------------------------*/
#detailShop {
  line-height: 2.8rem;
}
.Bc {
  background: #a1a1a1;
  padding: 0 10px;
}
.Bc li,
.Bc li a {
  color: #ffffff;
  display: inline-block;
}
.Bc li:nth-child(n + 2)::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
  margin: 0 10px;
  display: inline-block;
}
.Intro > * {
  margin-top: 20px;
}
.head h1 {
  font-size: 2.6rem;
  line-height: 3.5rem;
}
.head .sns li:nth-of-type(n + 2) {
  margin-left: 10px;
}
.Intro .head .sns a img {
  width: 35px;
  height: 35px;
}
.Shopping .Item + .Item {
  border-top: #808080 1px solid;
  margin-top: 20px;
  padding-top: 20px;
}
.Item .cart > *:nth-child(n + 2) {
  margin-top: 4px;
}
.Item .cart .amount {
  font-size: 1.8rem;
}
.Item .cart .price {
  font-size: 2.4rem;
}
.Item .cart .tax {
  font-size: 1.4rem;
}
.Item .cart .btnCart {
/*
	background: #595858 url("../../images/shop/cart.png") left 15px center no-repeat;
*/
	background-color: #595858;
  background-size: auto 25px;
  color: #ffffff;
  width: 100%;
  margin-top: 20px;
  padding-right: 15px;
  text-align: right;
  line-height: 35px;
  border-radius: 3px;
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 12px;
    top: 6px;
    width: 24px;
    height: 24px;
    background-image: url('../../images/common/cart.png');
    background-size: 100%;
  }
}

/*
* お知らせ
*/
.Info {
  /*
  * 見出し
  */
  h3 {
    border-bottom: #808080 1px solid;
  }
  dl {
    dt {
      font-weight: normal;
      @include pc() {
        width: 100px;
      }
    }
    dd {
      @include pc() {
        width: 760px;
      }
    }
  }

}
.Info > *:nth-child(n + 3) {
  margin-top: 10px;
}
.Info address {
  font-style: normal;
}
.Sns > * {
  margin-top: 30px;
}
.Sns .twitter-timeline {
  border: #eeeeee 1px solid !important;
}
.Sns > * iframe {
  width: 100%;
}

/*gmap
-------------------------------------------------------*/
.ggmap {
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  position: relative;
  overflow: hidden;
}
.ggmap iframe,
.ggmap object,
.ggmap embed {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/*pc
-------------------------------------------------------*/
@media print, screen and (min-width: 768px) {
  /*
	-------------------------------------------------------*/
  .square > div {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .square > div dt {
    width: 120px;
  }
  .square > div dd {
    flex: 1;
  }

  /* ul
	-------------------------------------------------------*/
  .ul1 li a::after {
    font-family: "Font Awesome 5 Free";
    content: "\f105";
    font-size: 1.6rem;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }

  /*dl
	-------------------------------------------------------*/
  .dl2.column2 > *:nth-child(n + 3) {
    margin-top: 10px;
  }

  /*shop
	-------------------------------------------------------*/
  .listThumb.column3 > * {
    width: 200px;
  }
  .listThumb.column3 li:nth-of-type(n + 4) {
    margin-top: 10px;
  }
  #listShop .cont .img {
    width: 150px;
  }
  #listShop .cont .note {
    width: 700px;
  }

  /*shop detail
	-------------------------------------------------------*/
  .head {
    align-items: flex-end;
  }
  .head h1 {
    margin-right: 30px;
  }
  .head .code {
    margin-right: auto;
  }
  .Intro .main .img {
    width: 400px;
  }
  .Intro .main .note {
    width: 450px;
  }
  .Intro .sub .img {
    width: 250px;
  }
  .Intro .sub .note {
    width: 600px;
  }
  .Item .img,
  .Item .cart {
    width: 150px;
  }
  .Item .note {
    width: 530px;
  }
  .Sns > * {
    width: 420px;
  }
}

/*sphone
-------------------------------------------------------*/
@media screen and (max-width: 767px) {
  /*共通
	-------------------------------------------------------*/
  #Wrapper h2,
  .sLink,
  .sBanner,
  .sSocial {
    margin-top: 20px;
  }

  /*side
	-------------------------------------------------------*/
  .sLink {
    ul {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      width: 100%;
      li {
        width: 48%;
      }
    }  
  }
  
  /*
  * 新着情報
  */
  .sNews {
    margin-bottom: 20px;
  }
  .sNews .dl1,
  .sCategory .ul1 {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .sNews .dl1,
  .sCategory .ul1,
  .fixBanner.flex {
    justify-content: space-between;
  }
  .sNews .dl1 > *,
  .sCategory .ul1 > *,
  .fixBanner.flex > * {
    width: 49%;
  }
  .sNews .dl1 > *:nth-child(2),
  .sCategory .ul1 > *:nth-child(2) {
    margin-top: 0px;
  }
  .sApp {
    display: none;
  }
  .fixBanner.flex,
  .fixBanner.flex > * {
    margin-top: 10px;
  }


  /*btn
	-------------------------------------------------------*/
  .btn1,
  .btn2 {
    width: 200px;
  }

  /*
	-------------------------------------------------------*/

  /*ul
	-------------------------------------------------------*/

  /*dl
	-------------------------------------------------------*/
  .dl2 > * {
    width: 100%;
  }
  .dl2 > *:nth-child(n + 2) {
    margin-top: 10px;
  }

  /*shop
	-------------------------------------------------------*/
  .listThumb {
    justify-content: space-between;
  }
  .listThumb > * {
    width: 49%;
  }
  .listThumb > *:nth-of-type(n + 3) {
    margin-top: 10px;
  }
  .listThumb li .note {
    padding: 5px 10px;
  }
  #listShop .cont:nth-child(n + 2) {
    margin-top: 20px;
    padding-top: 20px;
  }
  #listShop .cont .head,
  #detailShop .Intro .head {
    display: block;
  }
  #listShop .cont .head > *:nth-child(n + 2),
  #detailShop .Intro .head > *:nth-child(n + 2) {
    margin-top: 10px;
  }
  #listShop .cont .note,
  #detailShop .note {
    margin-top: 10px;
  }
  .Pager ul {
    justify-content: space-between;
  }
  .Pager .prev > *,
  .Pager .next > * {
    width: 40px;
  }

  /*shop detail
	-------------------------------------------------------*/
  #detailShop {
    line-height: 2em;
  }
  #detailShop .img {
    width: 100%;
  }
  .Bc li:nth-child(n + 2)::before {
    margin: 0 5px;
  }
  .Item .cart {
    width: 100%;
    margin-top: 10px;
    text-align: right;
  }
  .Item .cart > * {
    text-align: right;
  }
  .Item .cart .btnCart {
    background-position: left 40px center;
    width: 200px;
    padding-right: 40px;
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 24px;
      top: 6px;
      width: 24px;
      height: 24px;
      background-image: url('../../images/common/cart.png');
      background-size: 100%;
    }
  }
  .Info dl dt,
  .Info dl dd {
    width: 100%;
  }
  .Sns > * {
    width: 100%;
    margin-top: 20px;
  }

  /*gmap
  -------------------------------------------------------*/
  .ggmap {
    padding-bottom: 76.25%;
  }
}

/*
* 一般コンテンツ
*/
.sectionContent {
  /*
  * タイトル
  */
  h1 {
    font-size: 2.6rem;
    line-height: 1.2;
    @include pc() {
      font-size: 2.6rem;
    }
  }
}

/*
* 特選ショップ
*/
.specialtyShop {
  justify-content: flex-start;
  li {
    margin-right: 15px;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

/*
* ランキングタイトル
*/
.rankingTitle {
  img {
    width: 36px;
  }
}


/*
* 管理メニュー
*/
.sectionKanriMenu {
  margin-top: 3.0rem;
  border: 1px solid #A6A6A6;
  h6 {
    padding: 0.3rem 0;
    color: $color-white;
    font-weight: bold;
    text-align: center;
    background-color: #A6A6A6;
  }
  article {
    padding: 1.2rem;
    ol {
      li {

      }
    }
  }
}

/*
* ショップ管理画面用
*/
/*
* リスト
*/
.kanriListOperation {
  margin-bottom: 1.6rem;
}

/*
 * スマホメニューボタン
 */
 .spMenu {
  position: fixed;
  display: block;
  right: 0;
  top: 0;
  width: 56px;
  height: 56px;
  cursor: pointer;
  z-index: 100000;
  @include pc{
    display: none;
  }
  span {
    position: absolute;
    left: 18%;
    margin: 0;
    padding: 0;
    width: 64%;
    height: 2px;
    display: inline-block;
    transition: all 0.4s;
    box-sizing: border-box;
    background-color: #FFFFFF;
    border-radius: 2px;
    &:nth-of-type(1) { top: 18px; }
    &:nth-of-type(2) { top: 27px; }
    &:nth-of-type(3) { top: 36px; }
  }
}
.spMenu.active {
  background-color: #000000;
  span {
    &:nth-of-type(1) {
      -webkit-transform: translateY(9px) rotate(-45deg);
      transform: translateY(9px) rotate(-45deg);
    }
    &:nth-of-type(2) { opacity: 0; }
    &:nth-of-type(3) {
      -webkit-transform: translateY(-9px) rotate(45deg);
      transform: translateY(-9px) rotate(45deg);
    }
  }
}

/*
* 店舗トピックス一覧
*/
.sectionTopicsBlock {
  a {
    margin-top: 0 !important;
    padding-bottom: 0;
  }
  article {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    figure {
      position: relative;
      flex-grow: 0;
      margin-right: 8px;
      width: 48px;
      @include pc() {
        width: 48px;
      }
      &::before {
        content: "";
        display: block;
        padding-top: 100%;
      }
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        object-fit: cover;
      }
    }
    dl {
      flex-grow: 1;
      width: 252px;
      @include pc() {
        width: 252px;
      }
      dt {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      dd {
        width: 100%;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  /*
  * もっと見る
  */
  .btn1 {
    margin-top: 24px !important;
  }
}

/*
* 注文
*/
.sectionOrderInput {
  dl {
    margin-bottom: 0.8rem;
    padding-bottom: 0.8rem;
    border-bottom: 1px solid #d2d2d2;
    dt {
      font-weight: normal;
    }
    dd {

    }
  }
}

/*
* 会社概要
*/
.tableCompany {
  width: 100%;
  tr {
    td {
      padding: 1.6rem;
      border: 1px solid gray;
      &:first-of-type {
        color: #FFFFFF;
        background-color: #a6a6a6;
      }
      iframe {
        margin-top: 1.6rem;
        width: 100%;
        height: 400px;
      }
    }
  }

}

/*
* プライバシーポリシー
*/
.privacy {
  margin-bottom: 1.6rem;
  padding: 2.4rem;
  li {
    margin-bottom: 24px;
    padding-left: 1.0rem;
    list-style-type: decimal;
  }
}
